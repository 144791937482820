import {
    apiList,
    Api
} from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import sorttable from "@/components/sortTemplate.vue";
import paging from "@/components/pagings.vue";
// import { axios_file } from "@/assets/js/http_axios";
import inputnumber from '@/components/inputNumber.vue'
export default {
    inject: ["reload"],
    components: {
        // mytables,
        draggable,
        sorttable,
        paging,
        inputnumber,
    },
    data() {
        return {
            dialogFormVisible: false,
            number1: "",
            activeName: 'first',
            value: '',
            form: {
                name: '',
                region: ''
            },
            queryData: {
                packStartTime: '',
                packEndTime: '',
                channelTransportId: '',
                storageId: '',
                packStatus: '',
                memberId: '',
            },
            queryData1: {},
            options: [{
                name: "a",
                val: 0,
            },
            {
                name: "b",
                val: 1,
            },
            ],
            // ======公用组件==========
            pageNum: 1, //页码
            pageNum2: 1, //页码
            total: 0,
            sizeList: [10, 20, 50, 100, 200],
            size: 50, //一页几条
            size2: 50, //一页几条
            nextPage: false, //是否有下一页
            show_sortTableHeard: false, //显示弹窗
            show_sortTableHeard1: false, //显示弹窗
            show_upload: false, //显示上传弹窗
            myTableHeard: [], //我的表头
            originalTabelHeadeTitle: [],
            originalTabelHeadeTitle1: [],
            myTableHeard1: [], //我的表头
            selectLength: 0, //显示的长度
            selectLength1: 0, //显示的长度
            allCheck: false, //全选
            allCheck1: false, //全选
            tableHeigth: "50Vh",
            tabelHeadeTitle: [{
                name: "打包申请时间",
                field: "packTime",
                width: "180",
                sort: 22,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "打包批次号",
                field: "packNumber",
                width: "180",
                sort: 16,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "仓库",
                field: "storageName",
                width: "100",
                sort: 2,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "运输方式",
                field: "transportName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员名称",
                field: "nickname",
                width: "120",
                sort: 3,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员号",
                field: "memberId",
                width: "80",
                sort: 4,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "团号",
                field: "invitationCode",
                width: "180",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "打包状态",
                field: "packStatusShow",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "包裹备注",
                field: "packComment",
                width: "180",
                sort: 15,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "申请人",
                field: "applicantUserName",
                width: "80",
                sort: 15,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            ],
            tabelHeadeTitle1: [{
                name: "快递单号",
                field: "courierNumber",
                width: "80",
                sort: 5,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "快递公司",
                field: "expressName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "打包批次号",
                field: "billPackNumber",
                width: "180",
                sort: 16,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "仓库",
                field: "storageName",
                width: "80",
                sort: 2,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员名称",
                field: "memberName",
                width: "120",
                sort: 3,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员号",
                field: "memberId",
                width: "80",
                sort: 4,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "团号",
                field: "teamNumber",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "转单号",
                field: "channelNumber",
                width: "180",
                sort: 17,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "货物品类",
                field: "itemcategoryName",
                width: "80",
                sort: 11,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "包裹件数",
                field: "packageCount",
                width: "80",
                sort: 12,
                isShow: true,
                isTotal: true,
                isSort: true,
            },
            {
                name: "包裹重量(KG)",
                field: "packageWeight",
                width: "80",
                sort: 13,
                isShow: true,
                isTotal: true,
                isSort: true,
            },
            {
                name: "包裹体积(m³)",
                field: "packageVolume",
                width: "80",
                sort: 14,
                isShow: true,
                isTotal: true,
                isSort: true,
            },
            {
                name: "长(cm)",
                field: "packageLength",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "宽(cm)",
                field: "packageWidth",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "高(cm)",
                field: "packageHeight",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "包裹备注",
                field: "packageComment",
                width: "80",
                sort: 15,
                isShow: true,
                isTotal: false,
                isSort: true,
            },

            {
                name: "打包时间",
                field: "packTime",
                width: "80",
                sort: 22,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "打包人",
                field: "packerUserName",
                width: "80",
                sort: 22,
                isShow: true,
                isTotal: false,
                isSort: true,
            },


            ],
            tableData: [],
            tableData1: [],
            totalArr: [], //需要合计的字段
            totalArr1: [], //需要合计的字段
            className: "", //导出需要
            className1: "",
            fieldName: [], //下载字段名
            head: [], //下载表头字段
            fileList: [],
            uploadUrl: "", //上传路径
            sortName: '模板名', //排序名
            sortName1: '模板名', //排序名
            pageGroupName: 'ApplicationPackaging', //页面标识
            //    --------- 一条华丽的分割线  ---------
            isActive: true, //输入框绑定动态class
            judge: false, //输入框绑定动态class
            MoreConditions: '更多条件', //输入框绑定动态class
            singleData: [], //  编辑数据
            //    --------- 申请打包明细列表  ---------
            queryDetails: {
                courierNumber: '',
                billPackNumber: '',
                memberId: '',
                teamNumber: '',
                storageId: '',
                consigneeName: '',
                packageStatus: '',
                billPackStartTime: '',
                billPackEndTime: '',
            }, //  搜索数据表单
            storageIdArr: [], //  仓库下拉列表
            yunshuList: [], //运输下拉列表
            packageStatusArr: [], //  包裹状态下拉列表
            huiyuanList: [{
                nickname: '请选择',
                memberId: ''
            }], //会员下拉列表
            pageStart: 1, //  分页配置
            pageCount: 0,
            pageTotal: 50,

            // 输入快递单号弹窗
            showInputBox: false, // 
            showInputBox2: false, // 
            showInputBox3: false, // 
            time: [],
            time2: [],

        };
    },
    updated() {
        if (this.show_sortTableHeard) {
            // 排序表头
            let arr = this.tabelHeadeTitle;
            for (var i = 0; i < arr.length; i++) {
                arr[i].sort = i + 1;
            }
            this.tabelHeadeTitle = arr;
        }
        if (this.show_sortTableHeard1) {
            // 排序表头
            let arr = this.tabelHeadeTitle1;
            for (var i = 0; i < arr.length; i++) {
                arr[i].sort = i + 1;
            }
            this.tabelHeadeTitle1 = arr;
        }
    },
    created() {
        this.uploadUrl = this.common.uploadUrl;
        if (this.originalTabelHeadeTitle.length == 0) {
            this.originalTabelHeadeTitle = JSON.parse(
                JSON.stringify(this.tabelHeadeTitle)
            );
        }
        if (this.originalTabelHeadeTitle1.length == 0) {
            this.originalTabelHeadeTitle1 = JSON.parse(
                JSON.stringify(this.tabelHeadeTitle)
            );
        }
        this.getGroupFindFieldSort(); //获取用户自定义表头
        this.myGetStorageList() //  获取仓库下拉列表
        this.getStatusValList() //  获取包裹状态
        let endtime = tools.getCurrentDate(); //今天的时间;
        let sarttime = tools.getFlexDate(-10); //10天前
        this.time = [sarttime, endtime];
        this.time2 = [sarttime, endtime];
        this.queryData.packStartTime = sarttime + " 00:00:00";
        this.queryData.packEndTime = endtime + " 23:59:59";
        this.queryDetails.billPackStartTime = sarttime + " 00:00:00";
        this.queryDetails.billPackEndTime = endtime + " 23:59:59";
    },
    mounted() {
        this.getData();
        this.getTransportMethodList()
        this.getMemberDownList()
        //  = () => {
        //     return (() => {
        //         this.fetTableHeight();
        //     })();
        // };
    },
    watch: {},
    methods: {
        //时间
        paymentTime(e) {
            if (e && e.length > 0) {
                this.queryData.packStartTime = e[0] + " " + "00:00:00";
                this.queryData.packEndTime = e[1] + " " + "23:59:59";
            } else {
                this.queryData.packStartTime = "";
                this.queryData.packEndTime = "";
            }
            this.queryBtn_ok();
        },
        //时间
        paymentTime2(e) {
            if (e && e.length > 0) {
                this.queryDetails.billPackStartTime = e[0] + " " + "00:00:00";
                this.queryDetails.billPackEndTime = e[1] + " " + "23:59:59";
            } else {
                this.queryDetails.billPackStartTime = "";
                this.queryDetails.billPackEndTime = "";
            }
            this.queryBtn_ok1();
        },

        //点击输入快递单号 (打包明细)
        clickInput() {
            this.showInputBox = true
        },
        showInputBoxBtn(val) {
            this.showInputBox = false
            this.queryDetails.courierNumber = val;
            this.packPackageDescs();
        },
        closeDiasInputVal(val) {
            this.showInputBox = val || false
        },
        //点击输入批次号(打包明细)
        clickInput2() {
            this.showInputBox2 = true
        },
        showInputBoxBtn2(val) {
            this.showInputBox2 = false
            this.queryDetails.billPackNumber = val;
            this.packPackageDescs();
        },
        closeDiasInputVal2(val) {
            this.showInputBox2 = false;
        },
        //点击输入批次号(申请打包)
        clickInput3() {
            this.showInputBox3 = true
        },
        showInputBoxBtn3(val) {
            this.showInputBox3 = false
            this.number1 = val;
            this.getData();
        },
        closeDiasInputVal3(val) {
            this.showInputBox3 = false;
        },


        //获取会员下拉列表
        getMemberDownList() {
            Api.getMemberDownList().then((res) => {

                let huiyuanList = res.data.result.data || [];
                this.huiyuanList = huiyuanList

            })
        },
        //获取运输方式
        getTransportMethodList() {
            Api.getTransportMethodList().then((res) => {
                //console.log(res.data.result.data)
                this.yunshuList = res.data.result.data || []
            })
        },
        more() { //输入框绑定动态class
            this.isActive = !this.isActive
            this.judge = !this.judge
            this.fetTableHeight();
            if (this.judge) {
                this.MoreConditions = '收起条件'
            } else {
                this.MoreConditions = '更多条件'
            }
        },
        // 打印拣货单
        printOrder() {
            if (this.singleData.length < 1) {
                this.$message.warning('请至少选择一条打包单!');
                return
            }
            // console.log(this.singleData)
            let ids = [];
            this.singleData.forEach(item => {
                ids.push(item.billPackId)
            })
            // console.log(ids)
            this.$router.push({
                path: '/BusinessManagement/Warehousing/PrintPickingList',
                query: {
                    ids: JSON.stringify(ids)
                }
            })
        },

        getData() { //  获取数据
            let courierNumberList = tools.getOrderNum(this.number1) || [];

            let param = {
                pageStart: this.pageNum,
                pageTotal: this.size,
                memberId: this.queryData.memberId,
                packNumber: courierNumberList,
                channelTransportId: this.queryData.channelTransportId,
                invitationCode: '',
                packEndTime: this.queryData.packEndTime,
                packStartTime: this.queryData.packStartTime,
                packStatus: this.queryData.packStatus,
                storageId: this.queryData.storageId
            }
            Api.applyPackage(param).then((res) => {
                if (res.data.status === "success") {
                    this.className = res.data.result.className || "";
                    this.tableData = res.data.result.data || [];
                    this.total = res.data.result.pageCount || 0;
                    this.fetTableHeight();
                } else {
                    this.$message.error(res.data.message)
                }
            });
        },
        queryBtn_ok1() {
            this.pageStart = 1;
            this.packPackageDescs()

        },
        queryBtn_ok() { //  查询按钮
            let nidlist = new Array
            // nidlist = tools.getOrderNum(this.number1)
            // this.queryData.packNumber = nidlist
            this.pageNum = 1;
            this.getData();
        },

        allow() { //  允许打包
            if (this.singleData.length >= 1) {
                this.$confirm('是否允许此数据打包?', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning'
                }).then(() => {
                    let parm = new Object
                    let billPackId = new Array
                    for (var i = 0; i < this.singleData.length; i++) {
                        billPackId.push(this.singleData[i].billPackId)
                    }
                    parm.billPackId = billPackId
                    let sign = tools.getSign(parm)
                    parm.sign = sign
                    Api.allowPackage(parm).then(res => {
                        if (res.data.status === 'success') {
                            this.$message.success(res.data.message)
                            this.getData()
                        }
                    })
                })
            } else {
                this.$message.warning('请z至少勾选一条数据')
            }
        },


        suspend() { //  暂停打包
            if (this.singleData.length >= 1) {
                this.$confirm('是否暂停此条数据打包?', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning'
                }).then(() => {
                    let parm = new Object
                    let billPackId = new Array
                    for (var i = 0; i < this.singleData.length; i++) {
                        billPackId.push(this.singleData[i].billPackId)
                    }
                    parm.billPackId = billPackId
                    let sign = tools.getSign(parm)
                    parm.sign = sign
                    Api.stopPackage(parm).then(res => {
                        if (res.data.status === 'success') {
                            this.$message.success(res.data.message)
                            this.getData()
                        }
                    })
                })
            } else {
                this.$message.warning('请至少勾选一条数据')
            }
        },
        //取消打包申请
        rejection() {
            let singleData = this.singleData;
            let _this = this;
            if (singleData.length == 1) {
                this.$confirm("是否确定取消打包?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    let param = new Object();
                    param.billPackId = singleData[0].billPackId;
                    let sign = tools.getSign(param);
                    param.sign = sign;
                    Api.cancelPack(param).then((res) => {
                        if (res.data.status == "success") {
                            this.$message.success("取消申请成功");
                            _this.getData();
                        }
                    });
                });
            } else {
                this.$message.warning("请勾选一条数据");
            }
        },


        NewReject() { //  新建打包申请
            this.$router.push({
                path: '/BusinessManagement/Pickingconfirmation',
            }

            )
        },

        returnGoods() { //  修改
            if (this.singleData.length === 1) {
                this.$router.push({
                    path: '/BusinessManagement/Pickingconfirmation',
                    query: {
                        id: this.singleData[0].billPackId,
                        memberId: this.singleData[0].memberId,
                        nickname: this.singleData[0].nickname,
                        number: this.singleData[0].packNumber,
                    }
                })
            } else {
                this.$message.warning('请选择一条数据')
            }
        },
        resetFormQ(num) {
            this.number1 = ""
            if (num === 1) {
                this.queryData = {
                    packStartTime: '',
                    packEndTime: '',
                    channelTransportId: '',
                    storageId: '',
                    packStatus: '',
                    memberId: '',
                }
                this.time = []
            } else if (num === 2) {
                this.queryDetails = {
                    courierNumber: '',
                    billPackNumber: '',
                    memberId: '',
                    teamNumber: '',
                    storageId: '',
                    consigneeName: '',
                    packageStatus: '',
                    billPackStartTime: '',
                    billPackEndTime: '',
                }
                this.time2 = [];
            }
        },
        //    ---------  申请打包明细  --------

        myGetStorageList() { //  获取仓库下拉列表
            Api.getStorageList().then(res => {
                if (res.data.status === 'success') {
                    this.storageIdArr = res.data.result
                } else {
                    console.log(`${res.data.message} --- 仓库下拉`)
                }
            })
        },

        getStatusValList() { //  获取包裹状态
            Api.getStatusValList({
                tableAndFieldName: "bill_pack_v1.pack_status",
            }).then(res => {
                if (res.data.status === 'success') {
                    this.packageStatusArr = res.data.result
                } else {
                    console.log(`${res.data.message} --- 获取包裹状态`)
                }
            })
        },

        packPackageDescs() {

            let courierNumberList = tools.getOrderNum(this.queryDetails.courierNumber) || [];
            let billPackNumberList = tools.getOrderNum(this.queryDetails.billPackNumber) || [];
            // let params = JSON.parse(JSON.stringify(this.queryDetails));

            let paramss = {
                billPackEndTime: this.queryDetails.billPackEndTime,
                billPackNumber: billPackNumberList,
                billPackStartTime: this.queryDetails.billPackStartTime,
                consigneeName: this.queryDetails.consigneeName,
                courierNumber: courierNumberList,
                memberId: this.queryDetails.memberId,
                packageStatus: this.queryDetails.packageStatus,
                pageStart: this.pageStart,
                pageTotal: this.pageTotal,
                storageId: this.queryDetails.storageId,
                teamNumber: this.queryDetails.teamNumber,
            };
            Api.packPackageDescs(paramss).then((res) => {
                if (res.data.status === "success") {
                    this.className1 = res.data.result.className || "";
                    this.tableData1 = res.data.result.data || [];
                    this.pageCount = res.data.result.pageCount || 0;
                    this.fetTableHeight();
                    console.log(this.tableData1)
                    console.log('this.tableData1')

                } else {
                    this.$message.error(res.data.message)
                }
            });
        },

        //导出操作
        handleCommand_dc(command) {
            if (command == 'dc') {
                // 导出
                this.exportBtn()
            } else if (command == 'dcmb') {
                // 下载模板
                this.exportTemplateDownload()
            } else if (command == 'scmb') {
                // 上传模板
                this.upLoadBtn()
            }
        },
        // ===============================================导出部分=开始======
        // 上传导出模板
        uploadSectionFile(param) {
            console.log(param);
            const file = param.file,
                fileName = file.name;

            // 根据后台需求数据格式
            const form = new FormData();
            // 文件对象
            form.append("file", file);
            // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
            form.append("fileName", fileName);
            let signArr = [{
                key: "fileName",
                val: fileName,
            },];
            let sign = tools.getMD5Sign(signArr);
            form.append("sign", sign);
            // 项目封装的请求方法，下面做简单介绍
            Api.uploadFile(form)
                .then((res) => {
                    if (res.data.status === "success") {
                        let fileName = res.data.result.fileName;

                        let signArr = [{
                            key: "groupName",
                            val: this.pageGroupName,
                        },
                        {
                            key: "templateName",
                            val: this.sortName,
                        },
                        {
                            key: "fileName",
                            val: fileName,
                        },
                        ];
                        let sign_ = tools.getMD5Sign(signArr);
                        Api.saveExportTemplate({
                            groupName: this.pageGroupName,
                            templateName: this.sortName,
                            fileName: fileName,
                            sign: sign_,
                        }).then((res) => {
                            this.$message.success(res.data.message || "更新成功");
                            this.show_upload = false;
                        });
                    }
                })
                .catch((err) => { });
        },

        // 下载用户导出模板
        exportTemplateDownload() {
            var fieldName = this.fieldName;
            var head = this.head;
            if (head.length == 0) {
                let fieldNames = [];
                let heads = [];
                if (this.pageGroupName == 'ApplicationPackaging') {
                    for (var i = 0; i < this.myTableHeard.length; i++) {
                        if (this.myTableHeard[i].isShow) {
                            fieldNames.push(this.myTableHeard[i].field);
                            heads.push(this.myTableHeard[i].name);
                        }
                    }
                }
                if (this.pageGroupName == 'ApplicationPackaging2') {
                    for (var i = 0; i < this.myTableHeard1.length; i++) {
                        if (this.myTableHeard1[i].isShow) {
                            fieldNames.push(this.myTableHeard1[i].field);
                            heads.push(this.myTableHeard1[i].name);
                        }
                    }
                }
                fieldName = fieldNames;
                head = heads;
            }
            //加密数组格式
            let signArr = [{
                key: "fieldName",
                val: fieldName,
            },
            {
                key: "head",
                val: head,
            },
            ];
            let sign = tools.getMD5Sign(signArr);
            let param = {
                fieldName: fieldName,
                head: head,
                sign: sign,
            };
            Api.exportTemplateDownload(param).then((res) => {
                let downloadurl = res.data.result.fileUrl || "";
                //下载(这里其实是直接访问)
                var a = document.createElement("a");
                a.setAttribute("href", downloadurl);
                a.setAttribute("target", "_blank");
                a.click();
            });
        },
        // 真正导出
        templateDataExport(customizedExportId) {
            let className
            let that = this;
            let datas = []
            if (this.pageGroupName == 'ApplicationPackaging') {
                datas = that.tableData;
                className = that.className
            } else {
                console.log(that.claimedData)
                datas = that.tableData1
                className = that.className1
            }
            let parm = {
                customizedExportId: customizedExportId, //模板id
                className: className,
                data: datas,
            };
            let signarr = tools.getSignArr(parm);
            let signs_e = tools.getMD5Sign(signarr);
            let array = {
                customizedExportId: customizedExportId,
                className: className,
                data: JSON.stringify(datas),
                sign: signs_e,
            }
            Api.templateDataExport(array).then((res) => {
                //下载(这里其实是直接访问)
                let downloadurl = res.data.result.fileUrl;
                var a = document.createElement("a");
                a.setAttribute("href", downloadurl);
                a.setAttribute("target", "_blank");
                a.click();

            });
        },
        // 上传按钮
        upLoadBtn() {
            this.show_upload = true;
        },
        // 点击导出按钮
        exportBtn() {
            let that = this;
            let fieldName = [];
            let head = [];

            if (this.pageGroupName == 'ApplicationPackaging') {
                for (var i = 0; i < this.myTableHeard.length; i++) {
                    if (this.myTableHeard[i].isShow) {
                        fieldName.push(this.myTableHeard[i].field);
                        head.push(this.myTableHeard[i].name);
                    }
                }
            }
            if (this.pageGroupName == 'ApplicationPackaging2') {
                for (var i = 0; i < this.myTableHeard1.length; i++) {
                    if (this.myTableHeard1[i].isShow) {
                        fieldName.push(this.myTableHeard1[i].field);
                        head.push(this.myTableHeard1[i].name);
                    }
                }
            }
            this.fieldName = fieldName;
            this.head = head;

            // 1,先获取用户导出模板
            Api.getUserExportTemplate({
                groupName: this.pageGroupName,
            }).then((res) => {
                if (res.data.result && res.data.result[0].customizedExportId) {
                    // 有自定义模板

                    let customizedExportId = res.data.result[0].customizedExportId;
                    that.templateDataExport(customizedExportId);


                } else {
                    if (this.pageGroupName == 'ApplicationPackaging') {
                        tools.dynamicExport(
                            this.myTableHeard,
                            this.className,
                            this.tableData,
                            "申请打包列表"
                        );
                    } else {
                        tools.dynamicExport(
                            this.myTableHeard1,
                            this.className1,
                            this.tableData1,
                            "申请打包明细列表"
                        );
                    }
                }
            });
        },
        // ===============================================导出部分=结束======

        //   ------------- 设置表格动态高度  ------------

        resetHeight() { // 重置table高度
            return new Promise((resolve, reject) => {
                this.tableHeigth = 0;
                resolve();
            });
        },

        fetTableHeight() { // 设置table高度
            this.resetHeight().then((res) => {
                this.tableHeigth = this.getHeight();
                this.$nextTick(() => {
                    this.$refs.mytable.doLayout();
                });
            });
        },

        getHeight() { // 获取表格高度
            let windonHeight = document.body.clientHeight
            let queryHeigth
            let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
            if (this.judge) {
                queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
                if (this.activeName === 'second') {
                    queryHeigth = this.$refs.queryCriteria1.offsetHeight; //查询框
                }
            } else {
                queryHeigth = 50
            }
            let pagsHeigth = 40; //分页组件
            var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 130; //60是顶部导航 ,10==预留空位
            return Math.floor(windonHeight - otherHeight) || "auto"; //
        },


        //顶部标签页切换
        handleClick() {
            if (this.activeName === 'second') {
                this.pageGroupName = 'ApplicationPackaging2'
                this.SignInRecord()
                this.packPackageDescs()
            } else {
                this.pageGroupName = 'ApplicationPackaging'
                this.getGroupFindFieldSort()
            }
        },
        //签收记录表头获取
        SignInRecord() {
            let that = this;
            Api.getGroupFindFieldSort({
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
            }).then((res) => {
                let arr = res.data.result || [];
                if (arr.length > 0) {
                    if (arr[0].sortObject) {
                        that.myTableHeard1 = JSON.parse(arr[0].sortObject);
                        this.sortName1 = arr[0].sortName;
                    }
                } else {
                    that.myTableHeard1 = that.tabelHeadeTitle1;
                }
                let totalArr = [];
                //提取需要合计的字段
                for (var i = 0; i < that.myTableHeard1.length; i++) {
                    if (that.myTableHeard1[i].isTotal) {
                        totalArr.push(that.myTableHeard1[i].field);
                    }
                }
                that.totalArr1 = totalArr;
            });

            that.$nextTick(() => {
                that.$refs.mytable.doLayout();
            });
        },
        // 获取自定义的排序,没有就用默认的
        getGroupFindFieldSort() {
            let that = this;
            Api.getGroupFindFieldSort({
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
            }).then((res) => {
                let arr = res.data.result || [];
                if (arr.length > 0) {
                    if (arr[0].sortObject) {
                        that.myTableHeard = JSON.parse(arr[0].sortObject);
                        this.sortName = arr[0].sortName;
                    }
                } else {
                    that.myTableHeard = that.tabelHeadeTitle;
                }
                let totalArr = [];
                //提取需要合计的字段
                for (var i = 0; i < that.myTableHeard.length; i++) {
                    if (that.myTableHeard[i].isTotal) {
                        totalArr.push(that.myTableHeard[i].field);
                    }
                }
                that.totalArr = totalArr;
            });

            that.$nextTick(() => {
                that.$refs.mytable.doLayout();
            });
        },

        myModel() { // 设置模板按钮
            this.show_sortTableHeard = true;
            this.tabelHeadeTitle = JSON.parse(JSON.stringify(this.myTableHeard));
            let selectLength = 0;
            for (var i = 0; i < this.tabelHeadeTitle.length; i++) {
                if (this.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            this.selectLength = selectLength;
            if (selectLength === this.tabelHeadeTitle.length) {
                this.allCheck = true;
            } else {
                this.allCheck = false;
            }
        },

        myModel1() { //  设置模板按钮
            let that = this;
            that.show_sortTableHeard1 = true;
            that.tabelHeadeTitle1 = JSON.parse(JSON.stringify(that.myTableHeard1));
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
            if (selectLength === that.tabelHeadeTitle1.length) {
                that.allCheck1 = true;
            } else {
                that.allCheck1 = false;
            }
        },
        // 表格选择事件
        handleSelectionChange(e) {
            this.singleData = e
        },
        // 排序==>取消按钮
        cancelSortHeard() {
            this.show_sortTableHeard = false;
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
        },
        // 排序==>确认按钮
        confirmOk(e) {
            this.tabelHeadeTitle = e;
            this.myTableHeard = this.tabelHeadeTitle;
            let sortObject = this.myTableHeard;
            let signs = [{
                key: "sortName",
                val: this.sortName, //模板名称
            },
            {
                key: "groupName",
                val: this.pageGroupName, //页面标识
            },
            {
                key: "sortObject",
                val: JSON.stringify(sortObject),
            },
            ];

            let sign = tools.getMD5Sign(signs);

            let param = {
                // apiPath: apiList.api_packageList, //综合查询接口路径
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
                sortName: this.sortName, //综合查询接口路径
                sortObject: JSON.stringify(sortObject),
                sign: sign,
            };
            Api.pushFindFieldSort(param).then((res) => {
                this.$message.success(res.data.message || "操作成功");
                this.getGroupFindFieldSort();
            });
            this.show_sortTableHeard = false; //关闭弹窗
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
            this.show_sortTableHeard = false;
        },
        // 点击某一项
        checktab(idx, e) {
            let that = this;
            that.tabelHeadeTitle[idx].isShow = e;
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
            if (selectLength == that.tabelHeadeTitle.length) {
                that.allCheck = true;
            } else {
                that.allCheck = false;
            }
        },
        //全选
        checkAll(e) {
            let that = this;
            this.allCheck = e;
            for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
                this.tabelHeadeTitle[j].isShow = e;
            }
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
        },
        //关闭弹窗
        closeDias(e) {
            this.show_sortTableHeard = e;
        },
        //关闭弹窗
        closeDias1(e) {
            this.show_sortTableHeard1 = e;
        },
        // 合计
        getSummaries(param) {
            let that = this;
            const {
                columns,
                data
            } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                for (var i = 0; i < that.totalArr.length; i++) {
                    if (column.property == that.totalArr[i]) {
                        if (!values.every((value) => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return tools.mathToFixed(tools.add(prev, curr), 2)
                                } else {
                                    return prev;
                                }
                            }, 0);
                            sums[index] += "";
                        } else {
                            sums[index] = "---";
                        }
                    }
                }
            });
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
            return sums;

        },
        // 排序==>取消按钮(签收记录)
        cancelSortHeard1() {
            this.show_sortTableHeard1 = false;
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
        },
        // 排序==>确认按钮(签收记录)
        confirmOk1(e) {

            this.tabelHeadeTitle1 = e;

            this.myTableHeard1 = this.tabelHeadeTitle1;
            let sortObject = this.myTableHeard1;
            let signs = [{
                key: "sortName",
                val: this.sortName1, //模板名称
            },
            {
                key: "groupName",
                val: this.pageGroupName, //页面标识
            },
            {
                key: "sortObject",
                val: JSON.stringify(sortObject),
            },
            ];

            let sign = tools.getMD5Sign(signs);

            let param = {
                // apiPath: apiList.api_packageList, //综合查询接口路径
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
                sortName: this.sortName1, //综合查询接口路径
                sortObject: JSON.stringify(sortObject),
                sign: sign,
            };
            Api.pushFindFieldSort(param).then((res) => {
                this.$message.success(res.data.message || "操作成功");
                this.getGroupFindFieldSort();
            });
            this.show_sortTableHeard1 = false; //关闭弹窗
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
            this.show_sortTableHeard1 = false;
        },
        // 点击某一项
        checktab1(idx, e) {
            let that = this;
            that.tabelHeadeTitle1[idx].isShow = e;
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
            if (selectLength == that.tabelHeadeTitle1.length) {
                that.allCheck1 = true;
            } else {
                that.allCheck1 = false;
            }
        },
        //全选
        checkAll1(e) {
            let that = this;
            this.allCheck1 = e;
            for (var j = 0; j < this.tabelHeadeTitle1.length; j++) {
                this.tabelHeadeTitle1[j].isShow = e;
            }
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
        },
        // 合计
        getSummaries1(param) {
            let that = this;
            const {
                columns,
                data
            } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                for (var i = 0; i < that.totalArr1.length; i++) {
                    if (column.property == that.totalArr1[i]) {
                        if (!values.every((value) => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return tools.mathToFixed(tools.add(prev, curr), 2)
                                } else {
                                    return prev;
                                }
                            }, 0);
                            sums[index] += "";
                        } else {
                            sums[index] = "---";
                        }
                    }
                }
            });
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
            return sums;
        },

        //分页(申请打包)
        handleSizeChange(val) {
            this.size = val;
            this.getData();
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getData();
        },

        //分页(打包明细)
        handleSizeChange2(val) {
            this.pageTotal = val;
            this.packPackageDescs();
        },
        handleCurrentChange2(val) {
            this.pageStart = val;
            this.packPackageDescs();
        },
        // // 上一页
        // handlePrevPage() {
        //     this.pageNum = this.pageNum - 1 || 1;
        //     this.getData();
        // },
        // // 下一页
        // handleNextPage() {
        //     this.pageNum = this.pageNum + 1 || 1;
        //     this.getData();
        // },
    }
}